.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.cloud-services{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.cloud-services .part{
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
    height: 100%;
    min-height: 130px;
    background: #fff;
    position: relative;
}
.cloud-services .part:hover{
    margin-top: -0.5rem;
}
.cloud-services .part .name{
    margin: 0;
    padding: 0;
    font-size: 25px;
    font-weight: 600;
    color: #141d38;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed #0f3285;
}

.technologies-cloud{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
}
.technologies-cloud .tools{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    text-align: center;
}
.technologies-cloud .tools .tool{
    transition: ease 0.5s;
}
.technologies-cloud .tools .tool:hover{
    margin-top: -0.5rem;
}
.technologies-cloud .tools .tool .image{
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    min-width: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.technologies-cloud .tools .tool .image img{
    max-width: 100px;
    max-height: 100px;
    min-width: 30px;
    min-height: 30px;
}
.technologies-cloud .tools .tool p{
    margin: 0;
    padding-top: 5px;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.process-cloud{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.process-cloud .part{
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
    height: 100%;
    min-height: 130px;
    background: #fff;
    position: relative;
}
.process-cloud .part:hover{
    margin-top: -0.5rem;
}
.process-cloud .part .head{
    font-size: 25px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 10px;
    margin: 10px 0px;
}
.process-cloud .requirements{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;
}
.process-cloud .requirements:hover{
    background: #fff;
    color: #000;
    border: 1px solid #000;
}

.partner{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.partner .hire-btn{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.partner .hire-btn:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.our-company-cloud{
    background: #f8faf9;
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.our-company-cloud .head{
    text-align: center;
    margin-bottom: 3rem;
}
.our-company-cloud .icons{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 1px 15px 1px #0f328514;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
}
.our-company-cloud .icons:hover{
   margin-top: -1rem;
}
.our-company-cloud .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-cloud .icons .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}
.our-company-cloud .icons .icon img {
    max-width: 55px;
}


/*--responsive --*/
@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .connect {
        padding: 50px 20px;
    }
    .connect .btn-group{
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .partner{
        text-align: center;
    }
    .process-cloud .requirements{
        font-size: 13px;
    }
}
