.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}


.developer{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.developer{

}

.growth{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}


.hire-dev{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.our-company-onpageseo{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.our-company-onpageseo .head{
    text-align: center;
    margin-bottom: 3rem;
}
.our-company-onpageseo .icons{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 1px 15px 1px #0f328514;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
}
.our-company-onpageseo .icons:hover{
   margin-top: -1rem;
}
.our-company-onpageseo .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-onpageseo .icons .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}
.our-company-onpageseo .icons .icon img {
    max-width: 55px;
}

.app-mobile{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
}

/*--responsive --*/
@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .connect {
        padding: 80px 0;
    }
    .connect  .btn-group {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }
}
