.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}
.custom-banner .tagline{
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0;
}

.all-members{
  position: relative;
  top: -100px;
  margin-bottom: -100px;
}
.all-members .member{
  position: relative;
}
.all-members .member:hover img{
  filter: brightness(0.3);
  transition: ease 0.5s;
}
.all-members .member:hover .details{
  display: block;
  transition: ease 0.3s;
}
.all-members .member .details{
  position: absolute;
  bottom: 35px;
  text-align: center;
  width: 100%;
  display: none;
}
.all-members .member .details .name{
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 0;
  color: #fff;
}
.all-members .member .details .profile{
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 0;
  color: #fff;
}
.all-members .row:nth-child(2){
  position: relative;
  top: -25px;
}
.all-members .row:nth-child(3){
  position: relative;
  top: -50px;
}
.all-members .row:nth-child(4){
  position: relative;
  top: -75px;
}

.our-invester{
  background-image: url(../../public/images/team/strip-bg.png);
  padding: 80px 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #0f3285;
  color: #fff;
  text-align: center;
}
.our-invester .head{
  color: #fff;
}
.our-invester .tagline{
  font-size: 19px;
  color: #fff;
  margin-bottom: 2rem;
}
.our-invester .investers-logo{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.our-invester .investers-logo img{
  max-width: 150px;
  border-radius: 10px;
}

.work-with-us{
  padding: 4rem 0;
}
.work-with-us .center-part{
  background-image: url(../../public/images/team/strip-bg.png);
  padding: 30px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: #0f3285;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  margin-top: 2rem;
}
.work-with-us .center-part .head{
  font-size: 30px;
  color: #fff;
  margin-bottom: 5px;
}
.work-with-us .center-part .content p{
  font-size: 20px;
}
.work-with-us .center-part .btn-group{
 justify-content: center;
}
.work-with-us .center-part .btn-group a{
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 2rem;
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  margin-right: 1rem;
  transition: ease 0.5s;
  text-wrap: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.work-with-us .center-part .btn-group a:hover{
  background: #143382;
  color: #fff;
  border: 1px solid #fff;
}

@media screen and (max-width: 1200px) {
  .work-with-us .center-part .btn-group{
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 992px) {
  .work-with-us .center-part .btn-group{
    flex-wrap: nowrap;
  }
}
@media screen and (max-width: 768px) {
  .custom-banner{
    padding-top: 3rem;
  }
  .custom-banner .banner-head{
      font-size: 2rem;
  }
  .custom-banner .tagline{
    font-size: 1rem;
  }
  .all-members {
    margin-bottom: -50px;
  }
  .all-members .row:nth-child(2), .all-members .row:nth-child(3), .all-members .row:nth-child(4){
    top: 0;
  }
  .our-invester {
    padding: 40px 0;
  }
  .connect .btn-group{
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
  }
  .work-with-us .center-part{
    flex-direction: column;
    text-align: center;
  }
  .work-with-us .center-part .btn-group{
    flex-wrap: wrap;
  }
}