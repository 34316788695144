.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.mvp-service{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.mvp-service .part{
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
    height: 100%;
    min-height: 130px;
    background: #fff;
    position: relative;
}
.mvp-service .part:hover{
    margin-top: -0.5rem;
}
.mvp-service .part .name{
    margin: 0;
    padding: 0;
    font-size: 25px;
    font-weight: 600;
    color: #141d38;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed #0f3285;
}

.technologies-mvp{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.technologies-mvp .nav-tabs{
    border: none;
}
.technologies-mvp .nav-tabs .nav-item{
    border-bottom: 1px solid #ddd;
}
.technologies-mvp .nav-tabs .nav-item .nav-link{
    border-bottom: 3px solid transparent;
    background: transparent;
    border: none;
    color: #0f3285;
}
.technologies-mvp .nav-tabs .nav-item .nav-link.active{
    border-bottom: 3px solid #0f3285;
}
.technologies-mvp .tools{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    text-align: center;
    margin-top: 2rem;
}
.technologies-mvp .tools .tool{
    transition: ease 0.5s;
}
.technologies-mvp .tools .tool:hover{
    margin-top: -0.5rem;
}
.technologies-mvp .tools .tool .image{
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    min-width: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.technologies-mvp .tools .tool .image img{
    max-width: 80px;
    max-height: 80px;
    min-width: 30px;
    min-height: 30px;
}
.technologies-mvp .tools .tool p{
    margin: 0;
    padding-top: 5px;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.process-mvp{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.process-mvp .part{
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
    height: 100%;
    min-height: 130px;
    background: #fff;
    position: relative;
}
.process-mvp .part:hover{
    margin-top: -0.5rem;
}
.process-mvp .part::after{
    content: "1";
    height: 50px;
    width: 50px;
    right: 20px;
    position: absolute;
    top: 20px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
}
.process-mvp .col-lg-4:nth-child(1) .part::after{
    background: #e26826;
}
.process-mvp .col-lg-4:nth-child(2) .part::after{
    background: #3cc065;
    content: "2";
}
.process-mvp .col-lg-4:nth-child(3) .part::after{
    background: #3c50c0;
    content: "3";
}
.process-mvp .col-lg-4:nth-child(4) .part::after{
    background: #3cc065;
    content: "4";
}
.process-mvp .col-lg-4:nth-child(5) .part::after{
    background: #3c50c0;
    content: "5";
}
.process-mvp .col-lg-4:nth-child(6) .part::after{
    background: #e26826;
    content: "6";
}
.process-mvp .part .head{
    font-size: 25px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 10px;
    margin: 10px 0px;
    padding-right: 70px;
}
.process-mvp .part ul{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
}
.process-mvp .part ul li{
    padding-left: 25px;
    border-radius: 5px;
    font-size: 16px;
    position: relative;
}
.process-mvp .part ul li::before{
    content: "\f058";
    color: #143382;
    font-family: "Font Awesome 6 Free";
    position: absolute;
    left: 0;
}
.process-mvp .requirements{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;
}
.process-mvp .requirements:hover{
    background: #fff;
    color: #000;
    border: 1px solid #000;
}

.partner{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.partner .hire-btn{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.partner .hire-btn:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.industry-mvp{
    position: relative;
}
.industry-mvp .heading{
    position: absolute;
    top: 10%;
    z-index: 5;
    width: 100%;
    text-align: center;
    font-size: 10vw;
    text-transform: uppercase;
    font-weight: 600;
    opacity: 0.3;
    color: #ffffff;
}
.industry-mvp .part{
    position: relative;
    overflow: hidden;
    height: 500px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}
.industry-mvp .part .image{
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
}
.industry-mvp .part:hover .image::after{
    content: "";
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, #0f328523, transparent);
    position: absolute;
    left: 0;
}
.industry-mvp .part:hover .image::after{
    background: linear-gradient(0deg, #0f3285a1, transparent);
}
.industry-mvp .part .image img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.industry-mvp .part .content{
    position: absolute;
    z-index: 2;
    text-align: center;
    width: 100%;
}
.industry-mvp .part .content .name{
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    text-shadow: 3px 3px 4px #001850;
    background: #001341ab;
    width: 100%;
    padding: 1rem;
    min-height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.industry-mvp .swiper-button-prev2:hover, .industry-mvp .swiper-button-next2:hover{
    background: #00185092;
}
.industry-mvp .swiper-button-prev2, .industry-mvp .swiper-button-next2{
    display: block;
    position: absolute;
    top: 45%;
    height: 50px;
    width: 50px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #ffffff;
    text-shadow: 1px 1px 1px #0b1837;
}
.industry-mvp .swiper-button-prev2{
    left: 0;
}
.industry-mvp .swiper-button-next2{
    right: 0;
}


.our-company-mvp{
    background: #f8faf9;
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.our-company-mvp .head{
    text-align: center;
    margin-bottom: 3rem;
}
.our-company-mvp .icons{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 1px 15px 1px #0f328514;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
}
.our-company-mvp .icons:hover{
   margin-top: -1rem;
}
.our-company-mvp .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-mvp .icons .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}
.our-company-mvp .icons .icon img {
    max-width: 55px;
}


/*--responsive --*/
@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .connect {
        padding: 50px 20px;
    }
    .connect .btn-group{
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .partner{
        text-align: center;
    }
    .process-mvp .requirements{
        font-size: 13px;
    }
}
