.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.deliverable{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.deliverable .part{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
    height: 100%;
    min-height: 130px;
    background: #fff;
    position: relative;
}
.deliverable .part:hover{
    margin-top: -0.5rem;
}
.deliverable .part .name{
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 500;
    color: #141d38;
}
.deliverable .part::after{
    border-radius: 25px;
    content: "";
    height: 4px;
    left: 15px;
    position: absolute;
    top: -2px;
    width: 36px;
    z-index: 9;
}
.deliverable .col-lg-4:nth-child(1) .part::after, .deliverable .col-lg-4:nth-child(6) .part::after{
    background: #e26826;
}
.deliverable .col-lg-4:nth-child(2) .part::after, .deliverable .col-lg-4:nth-child(4) .part::after{
    background: #3cc065;
}
.deliverable .col-lg-4:nth-child(3) .part::after, .deliverable .col-lg-4:nth-child(5) .part::after{
    background: #3f97eb;
}
.deliverable .requirements{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;
}
.deliverable .requirements:hover{
    background: #fff;
    color: #000;
    border: 1px solid #000;
}

.technologies-uiux{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.technologies-uiux .tools{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    text-align: center;
}
.technologies-uiux .tools .tool{
    transition: ease 0.5s;
}
.technologies-uiux .tools .tool:hover{
    margin-top: -0.5rem;
}
.technologies-uiux .tools .tool .image{
    background: #fff;
    padding: 1rem;
    border-radius: 5px;
    min-width: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.technologies-uiux .tools .tool .image img{
    min-width: 30px;
    min-height: 30px;
}
.technologies-uiux .tools .tool p{
    margin: 0;
    padding-top: 5px;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.process{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.process .part{
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
    height: 100%;
    min-height: 130px;
    background: #fff;
    position: relative;
}
.process .part:hover{
    margin-top: -0.5rem;
}
.process .part::after{
    content: "1";
    height: 50px;
    width: 50px;
    right: 15px;
    position: absolute;
    top: -10px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    border-radius: 5px;
}
.process .col-lg-4:nth-child(1) .part::after, .process .col-lg-4:nth-child(3) .part::after{
    background: #e26826;
}
.process .col-lg-4:nth-child(2) .part::after{
    background: #3cc065;
    content: "2";
}
.process .col-lg-4:nth-child(3) .part::after{
    background: #3c50c0;
    content: "3";
}
.process .part .head{
    font-size: 25px;
    border-bottom: 1px dashed #ddd;
    padding-bottom: 10px;
    margin: 10px 0px;
}
.process .part ul{
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 10px;
}
.process .part ul li{
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 13px;
}
.process .part ul li:nth-child(odd){
    border: 1px solid #e26826;
}
.process .part ul li:nth-child(even){
    border: 1px solid #3cc065;
}
.process .requirements{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 2rem;
}
.process .requirements:hover{
    background: #fff;
    color: #000;
    border: 1px solid #000;
}

.partner{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.partner .hire-btn{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.partner .hire-btn:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.our-company-uiux{
    background: #f8faf9;
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.our-company-uiux .head{
    text-align: center;
    margin-bottom: 3rem;
}
.our-company-uiux .icons{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 1px 15px 1px #0f328514;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
}
.our-company-uiux .icons:hover{
   margin-top: -1rem;
}
.our-company-uiux .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-uiux .icons .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}
.our-company-uiux .icons .icon img {
    max-width: 55px;
}


/*--responsive --*/
@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .connect {
        padding: 50px 20px;
    }
    .connect .btn-group{
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .partner{
        text-align: center;
    }
}
