.serviceform{
    background: #f8faf9;
    padding: 5rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.serviceformWeb{
    background: #fff;
    padding: 5rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.serviceform form{
    box-shadow: 0 0 3px #ddd;
    border-radius: 5px;
    padding: 3rem 2rem;
}
.serviceform form #captcha-container div:nth-last-child(1){
    display: flex;
    align-items: center;
    gap: 10px;
}
.serviceform form #captcha-container div:nth-last-child(1) #refreshCaptcha{
    margin: 0 !important;
}
.serviceform form input:focus, .serviceform form textarea:focus{
    outline: none;
    box-shadow: none;
}
.serviceform form .custom-btn{
    width: 100%;
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}