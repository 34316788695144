
.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.about-sec{
    padding: 8rem 0;
    min-height: 100vh;
}
/* .about-sec .about-area {
    border: 1px solid #E3E7F0;
    padding: 80px;
    border-radius: 30px;
} */
.about-sec .about-area .check-list{
    list-style: none;
    padding: 0;
    line-height: 1.8;
    margin: 2rem 0;
}
.about-sec .about-area .check-list li {
    display: flex;
    align-items: flex-start;
    gap: 7px;
}
.about-sec .about-area .check-list i{
   color: #143382;
   margin-top: 7px;
}
.img-box {
    position: relative;
    z-index: 2;
    text-align: right;
}
/* .img-box .imgarea {
    position: relative;
    text-align: right;
    z-index: 2;
    border-radius: 30px;
} */
.img-box .imgarea img{
    border-radius: 30px;
}
.img-box .experience {
    position: absolute;
    bottom: 80px;
    left: 0px;
    z-index: 2;
    padding: 30px 30px 30px 40px;
    background: #143382;
    border-radius: 25px 0px 0px 25px;
    width: 340px;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    font-family: var(--title-font);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    min-height: 2%;
}
.img-box .experience .experience-year {
    font-size: 54px;
    font-weight: 700;
    letter-spacing: -1.08px;
    color: #fff;
    margin-bottom: 0px;
}

.misson-vision{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 100vh;
}

.expertise {
    background-color: #0f3285;
    padding: 8rem 0;
}
.expertise .head, .expertise .head span {
    color: #fff;
}
.expertise .choose-feature{
    text-align: center;
    background: #fff;
    padding: 3rem 2rem;
    height: 100%;
    border-radius: 7px;
}
.expertise .choose-feature .box-icon {
    position: relative;
    z-index: 2;
    min-width: 50px;
}
.expertise .choose-feature .box-icon:before {
    content: "";
    position: absolute;
    top: 5px;
    left: auto;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #E8ECF6;
    border-radius: 999px;
    z-index: -1;
}
.expertise .choose-feature .box-icon img {
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin-left: 10px;
}
.expertise .choose-feature .box-title {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.417;
    color: #141d38;
    text-decoration: none;
    cursor: pointer;
    padding: 1rem 0;
    display: block;
}
.expertise .choose-feature:hover .box-icon img {
    -webkit-transform: scale(-1) rotate(180deg);
    -ms-transform: scale(-1) rotate(180deg);
    transform: scale(-1) rotate(180deg);
}

.consultation-about{
    padding: 8rem 0;
    background: #f8faf9;
}
.consultation-about .part{
    background-color: #0f3285; 
    text-align: center;
    padding: 3rem 2rem;
    height: 100%;
    border-radius: 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.consultation-about .part .icon{
    width: 90px;
    height: 90px;
    line-height: 90px;
    border-radius: 90px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.22);
    background: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    color: #fff;
    font-size: 30px;
}
/* .consultation-about .part p, .consultation-about .part a{
    margin: 0;
    color: #fff;
    text-decoration: none;
    line-break: anywhere;
} */

.consultation-about .part p, .consultation-about .part a{
    margin: 0;
    color: #fff;
    text-decoration: none;
}
.consultation-about .col-lg-4:nth-child(3) .part a{
    line-break: anywhere;
}


/*--mobile responsive --*/

@media screen and (max-width: 575px) {
    .about-sec, .about-sec .head, .about-sec p, .misson-vision .head, .misson-vision p{
        text-align: center;
    }
    .about-sec .head{
        text-align: center;
    }
    .about-sec .about-area .check-list{
        text-align: center;
    }
    .about-sec .about-area .check-list li{
        display: inline-block;
    }
}