.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent; /* Semi-transparent background */
    z-index: 9999;
  }

  .blurred-form {
    filter: blur(2px);
    pointer-events: none; 
  }

.form-possition-contactpage {
    background: #f8faf9;
    border: 1px solid #0f3285;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
     min-height: fit-content; 
     width: fit-content;
}
.form-possition-contactpage .details {
    background-color: #0f3285;
    padding: 2rem;
    height: fit-content;
}
.form-possition-contactpage .form-side {
    background: #fff;
    padding: 2rem;
    height: fit-content;
}
 

.form-possition-contactpage .details .head{
    font-size: 1.5rem;
    line-height: 1;
}
.form-possition-contactpage .details .part{
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 20px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.form-possition-contactpage .details .part .icon{
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border-radius: 50%;
    transition: ease 0.5s;
    border: 1px solid transparent;
}
.form-possition-contactpage .details .part:hover .icon{
    background: #021f61;
    border: 1px solid #fff;
    transform: rotate(360deg);
}
.form-possition-contactpage .details .part .icon i{
    font-size: 30px;
    color: #0f3285;
    padding: 40px;
}
.form-possition-contactpage .details .part:hover .icon i{
    color: #fff;
}
.form-possition-contactpage .details .part a{
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
}
.form-possition-contactpage .details .part p{
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
}
.form-possition-contactpage .details .social-icons{
    display: flex;
    list-style: none;
    justify-content: center;
    width: 100%;
    gap: 25px;
    margin-top: 2rem;
}
.form-possition-contactpage .details .social-icons li{
    height: 50px;
    width: 50px;
    display: flex;
    background: #021f61;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: ease 0.2s;
}
.form-possition-contactpage .details .social-icons li:hover{
    transform: scale(0.9);
}
.form-possition-contactpage .form-side{
    padding: 1rem;
    background: #fff;
}
.form-possition-contactpage .form-side .head{
    font-size: 2rem;
    margin-bottom: 1rem;
}
.form-possition-contactpage .details .head, .form-possition-contactpage .details .head span{
    color: #fff;
}

.form-possition-contactpage .form-control, .form-possition-contactpage .form-control:focus{
    outline: none;
    box-shadow: none;
}
.form-possition-contactpage .form-label{
    color: #141d38;
}
.form-possition-contactpage .form-control{
    border-color: #141d38;
}
.form-possition-contactpage .custom-btn{
    background-color: #143382;
    color: #fff;
    border-color: #fff;
}
.form-possition-contactpage .custom-btn:hover{
    background-color: #fff;
    color: #143382;
    border-color: #143382;
}

@media screen and (max-width: 1200px) {
    .form-possition-contactpage .details, .form-possition-contactpage .form-side{
        width: 100%;
    }
}
@media screen and (max-width: 575px) {
    .form-possition-contactpage .details .part {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 20px;
        margin-top: 1rem;
        margin-bottom: 1rem;
        flex-direction: column;
        background: #021f61;
        padding: 2rem;
        border-radius: 20px;
        text-align: center;
    }
    .form-possition-contactpage .details .social-icons li {
        background: #0f3285;
    }
    .form-possition-contactpage .details .social-icons{
        flex-wrap: wrap;
    }
}