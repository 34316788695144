.circular-progress-wrapper {
    position: relative;
    display: inline-block;
}

.circular-progress-background {
    fill: none;
    stroke: #eeeeee; /* Light gray for background */
}

.circular-progress-bar {
    fill: none;
    stroke: #0f3285 !important; /* Bootstrap primary blue color */
    stroke-linecap: square; /* Round the stroke ends */
}

.circular-progress text {
    font-family: 'Arial', sans-serif;
}
