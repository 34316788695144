.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}


.startup{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}

.digital-process-dm{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.digital-process-dm .process{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    flex-wrap: wrap;
}
.digital-process-dm .process .point-link{
    position: relative;
    background: #fff;
    border-radius: 10px;
    padding: 2rem;
    max-width: 29%;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
}
.point-link{
    text-decoration: none;
    color: inherit;
}
.digital-process-dm .process .point-link::after{
    content: ">";
    position: absolute;
    right: -2rem;
    top: 45%;
    background: #0f3285;
    border: 10px solid #d1dfff;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: monospace;
    color: #fff;
    border-radius: 50%;
    transition: ease 0.5s;
}
.digital-process-dm .process .point:nth-last-child(1):after{
    display: none;
}
.digital-process-dm .process .point:hover::after{
    right: -3rem;
}
.digital-process-dm .process .point .num{
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
    font-size: 25px;
    font-weight: 600;
    background: #0f3285;
    color: #fff;
    border-radius: 0 10px 0 0;
}
.digital-process-dm .process .point .head{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
}
.digital-process-dm .process .point .head img{
    max-width: 55px;
}
.digital-process-dm .process .point .head .name{
    margin-bottom: 0;
}


.digital-service{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.digital-service .part{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;
    border-radius: 10px;
    padding: 2rem;
    box-shadow: 0 1px 15px 1px #0f328514;
    transition: ease 0.5s;
}
.digital-service .part:hover{
    margin-top: -0.5rem;
}
.digital-service .part img{
    max-width: 60px;
}
.digital-service .part .name{
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-weight: 700;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.our-fetaures-dm{
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
}
.our-fetaures-dm .head{
    margin-bottom: 3rem;
}
.our-fetaures-dm .circle_percent {
    font-size: 120px;
    width: 1em;
    height: 1em;
    position: relative;
    background: #eee;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 20px;
}

.our-fetaures-dm .circle_inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: 1em;
    clip: rect(0, 1em, 1em, 0.5em);
}
.our-fetaures-dm .round_per {
    position: absolute;
    left: 0;
    top: 0;
    width: 1em;
    height: 1em;
    background: #143687;
    clip: rect(0, 1em, 1em, 0.5em);
    transform: rotate(180deg);
    transition: 1.05s;
}
.our-fetaures-dm .content {
    width: 70%;
}
.our-fetaures-dm .content .small-head {
    margin-bottom: 10px;
}

.our-fetaures-dm .percent_more .circle_inner {
    clip: rect(0, 0.5em, 1em, 0);
}

.our-fetaures-dm .percent_more::after {
    position: absolute;
    left: 0.5em;
    top: 0;
    right: 0;
    bottom: 0;
    background: #143687;
    content: '';
}

.our-fetaures-dm .circle_inbox {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background: #fff;
    z-index: 3;
    border-radius: 50%;
}

.our-fetaures-dm .percent_text {
    position: absolute;
    font-size: 36px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
}

.technologie-dm{
    background: #f8faf9;
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.technologie-dm .head{
    text-align: center;
    margin-bottom: 3rem;
}
.technologie-dm .icons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #fff;
    box-shadow: 0 1px 15px 1px #0f328514;
    border-radius: 10px;
    padding: 1rem;
    transition: ease 0.5s;
}
.technologie-dm .icons:hover{
    margin-top: -0.5rem;
}
.technologie-dm .icons img{
    max-width: 150px;
}
.technologie-dm .icons h4{
    font-size: 18px;
    margin-bottom: 0;
}

.partner{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.partner .hire-btn{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.partner .hire-btn:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.our-company{
    background: #f8faf9;
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.our-company .head{
    text-align: center;
    margin-bottom: 3rem;
}
.our-company .icons{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 1px 15px 1px #0f328514;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
}
.our-company .icons:hover{
   margin-top: -1rem;
}
.our-company .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company .icons .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}
.our-company .icons .icon img {
    max-width: 55px;
}


/*--responsive --*/
@media screen and (max-width: 1200px) {
    .digital-process-dm .process {
        gap: 2rem;
    }
    .digital-process-dm .process .point-link{
        max-width: 40%;
    }
}
@media screen and (max-width: 768px) {
    .digital-process-dm .part{
        justify-content: center;
    }
    .digital-process-dm .process .point-link{
        max-width: 100%;
    }
    .digital-process-dm .process .point-link::after {
        right: auto;
        top: auto;
        bottom: -1rem;
        left: 45%;
        transform: rotate(90deg);
    }
    .why-us .part{
        text-align: center;
    }
    .partner{
        text-align: center;
    }
    .connect {
        padding: 80px 0;
    }
    .connect  .btn-group {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }
    .our-fetaures-dm .part {
        justify-content: center;
    }
    .our-fetaures-dm .content{
        width: 100%;
        text-align: center;
    }
}
