.breadcrumb {
  font-size: 1.4rem;
  color: #333;
  display: flex;
  justify-content: center;
}

.breadcrumb ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb li {
  display: inline; /* Inline list items */
}

.breadcrumb li + li::before {
  content: ' / '; /* Slash separator between items */
  padding: 0 10px; /* Increased padding for better spacing */
  color: #fff;
}

.breadcrumb a {
  text-decoration: none;
  color: #fff; /* Non-active items color is white */
  font-size: 16px; /* Increased font size */
}

.breadcrumb a:hover {
  text-decoration: none;
}

.breadcrumb .current {
  color: #6c757d; 
  font-weight: bold; 
  pointer-events: none; 
  font-size: 18px;
}

/* Ensure non-active items (home, service) are white */
.breadcrumb li:not(.current) a {
  color: #fff; 
  font-size: 18px; 
  text-decoration: none; 
}

.breadcrumb li:not(.current) a :hover{
  color: rgb(176, 176, 255);
}

/* Adjust icon if needed */
.breadcrumb i.icon-home {
  font-size: 18px;
  color: #fff;
  margin-right: 5px;
}
