#captchaDisplay{
    color: #fff !important;
}
/* The overlay that will cover the form */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: transparent; /* Semi-transparent background */
    z-index: 9999;
  }

  .blurred-form {
    filter: blur(2px);
    pointer-events: none; 
  }
