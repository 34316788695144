.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.term-details{
    padding: 0 8rem 4rem ;
    background: #f8faf9 ;
    min-height: 100vh ;
}
.term-details .details-info{
    padding: 2rem;
    background: #fff;
    border-radius: 1rem;
    position: relative;
    top: -2rem;
    z-index: 2;
}
.term-details .details-info .part{
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 0 0px 2px #0f3285;
    text-align: center;
    height: 100%;
}

@media screen and (max-width: 575px) {
    .term-details{
        padding: 1rem;
    }
}