
.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.banner-bottom-part{
    position: relative;
    top: -4rem;
}
.banner-bottom-part .part .rotate{
    position: relative;
    bottom: 0;
    z-index: 10;
    padding: 2rem;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.20);
    transition: ease 0.8s;
    width: 100%;
    overflow: hidden;
    border: 1px solid #ffffff52;
}
.banner-bottom-part .part .rotate:after{
    content: "";
    position: absolute;
    height: 200px;
    width: 200px;
    right: -100px;
    top: -100px;
    background: #0a2a77;
    border-radius: 50%;
    transition: ease 0.8s;
    opacity: 0;
}
.banner-bottom-part .part:hover .rotate:after{
    opacity: 1;
}
.banner-bottom-part .part:hover .rotate{
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
    background: #0f3285;
}
.banner-bottom-part .part:hover .rotate i, .banner-bottom-part .part:hover .rotate .head, .banner-bottom-part .part:hover .rotate p, .banner-bottom-part .part:hover .rotate a, .banner-bottom-part .part:hover .rotate a span{
    color: #fff;
}
.banner-bottom-part .part i{
    color: #0c5adb;
    font-size: 60px;
    margin-bottom: 15px;
    display: block;
}
.banner-bottom-part .part .head{
    font-weight: 700;
    font-size: 23px;
    color: #141d38;
    margin-bottom: 10px;
}
.banner-bottom-part .part p{
    color: #686868;
    font-size: 16px;
    line-height: 1.6;
}
.banner-bottom-part .part a{
    font-size: 17px;
    color: #141d38;
    text-decoration: none;
    text-transform: uppercase;
}
.banner-bottom-part .part a span{
    letter-spacing: -2px;
    font-family: monospace;
    position: relative;
    right: 0;
    transition: ease 0.4s;
    margin-top: -1px;
}
.banner-bottom-part .part a:hover span{
    right: -10px;
}

.work-process{
    padding: 6rem 0;
}
.work-process .points{
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.work-process .points .part{
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 20px;
}
.work-process .points .part .icon{
    background: #c7d7ff;
    min-width: 80px;
    min-height: 80px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
}
.work-process .points .part .icon i{
    font-size: 35px;
    color: #0f3285;
}
.work-process .points .part .content .head{
    font-size: 23px;
    margin-bottom: 5px;
}
.work-process .points .part .content p{
    font-size: 16px;
    margin-bottom: 5px;
}

.technologies{
    padding: 6rem 0;
    background: linear-gradient(45deg, #143382db, #143382e6), url(../../public/images/career.png) no-repeat fixed;
    background-size: cover;
}
.technologies .head{
    margin-bottom: 3rem;
}
.technologies .head, .technologies .head span{
    text-align: center;
    color: #fff;
}
.technologies .part{
    display: flex;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: nowrap;
    border: 1px solid #ffffff57;
    border-radius: 7px;
    padding: 2rem 1rem;
    background: #0f328529;
    position: relative;
    overflow: hidden;
    transition: ease 0.5s;
}
.technologies .part .icon{
    position: relative;
    z-index: 1;
}
.technologies .part .icon i{
    font-size: 50px;
    color: #ffffffb8;
}
.technologies .part .content{
    position: relative;
    z-index: 1;
}
.technologies .part .content .head{
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 7px;
}
.technologies .part .content p{
    font-size: 16px;
    color: #fff;
    margin-bottom: 10px;
}
.technologies .part .content a {
    font-size: 17px;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
}
.technologies .part .content a span {
    letter-spacing: -2px;
    font-family: monospace;
    position: relative;
    right: 0;
    transition: ease 0.4s;
    margin-top: -1px;
}
.technologies .part a:hover span{
    right: -10px;
}
.technologies .part:after {
    content: "";
    height: 0;
    width: 100%;
    background: #044dc4;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: ease 0.5s;
}
.technologies .part:hover{
    transform: scale(1.05);
}
.technologies .part:hover:after {
    height: 100%;
}

.work-steps{
    padding: 6rem 0;
}
.work-steps .head{
    text-align: center;
    margin-bottom: 3rem;
}
.work-steps .part{
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.work-steps .part .icon{
    height: 150px;
    width: 150px;
    border-radius: 50%;
    border: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: ease 0.8s;
}
.work-steps .part:hover .icon{
    transform: rotateY(180deg);
    background: #001850;
}
.work-steps .part .icon i{
    font-size: 50px; 
    color: #001850;
    transition: ease 0.8s;
}
.work-steps .part:hover .icon i{
    transform: rotateY(180deg);
    color: #fff;
}
.work-steps .part .icon:after{
    content: "1";
    position: absolute;
    height: 45px;
    width: 45px;
    background: #001850;
    color: #fff;
    border-radius: 50%;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
}
.work-steps .col-lg-3:nth-child(2) .part .icon:after{
    content: "2";
}
.work-steps .col-lg-3:nth-child(3) .part .icon:after{
    content: "3";
}
.work-steps .col-lg-3:nth-child(4) .part .icon:after{
    content: "4";
}
.work-steps .part:hover .icon:after{
    left: 0;
    transform: rotateY(180deg);
    background: #044dc4;
}
.work-steps .part:after{
    content: "";
    background: url(../../public/images/process-arrow.png) no-repeat top right;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 10%;
    right: -20%;
    transition: ease 0.8s;
}
.work-steps .part:hover:after{
    right: -25%;
}
.work-steps .col-lg-3:nth-last-child(1) .part:after{
    display: none;
}
.work-steps .part .content{
    margin: 1rem 0;
}
.work-steps .part .content .head{
    font-size: 25px;
    font-weight: 500;
    color: #141d38;
    margin-bottom: 1rem;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 6rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.our-company-sec{
    background: #f8faf9;
    padding: 6rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.our-company-sec .head{
    text-align: center;
    margin-bottom: 4rem;
}
.our-company-sec .icons{
    background: #fff;
    border: 1px solid #eee;
    filter: drop-shadow(0px 10px 50px rgba(217, 217, 217, 0.5));
    border-radius: 7px;
    padding: 0 0.5rem 2rem 0.5rem;
    min-height: 190px;
    transition: 0.4s ease-in-out;
    text-align: center;
    margin-bottom: 3rem;
    transition: ease 0.5s;
}
.our-company-sec .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 400;
    margin-bottom: 0;
}
.our-company-sec .icons .icon{
    position: relative;
    z-index: 2;
    top: -2rem;
    display: inline-block;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    background-color: #f4f7ff;
    border: 3px solid #ffffff;
    padding: 17px;
    transition: ease 0.3s;
}
.our-company-sec .icons:hover .icon{
    box-shadow: 0 3px 0px 0px #0f3285;
}
.our-company-sec .icons .icon img {
    transition: 0.4s ease-in-out;
    max-width: 50px;
}

@media screen and (max-width: 720px) {
    .work-steps .part:after{
        content: none;
    }
}