.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.portfolio{
  padding: 60px 0;
}
.portfolio .part{
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 7px #ddd;
  border-radius: 10px;
  border: 1px solid #ddd;
  overflow: hidden;
  position: relative;
  max-height: 360px;
}
.portfolio .part img{
  object-fit: cover;
  width: 100%;
}
.portfolio .part .details{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #0f3285d1;
  transition: ease 0.5s;
  display: none;
}
.portfolio .part:hover .details{
  display: flex;
  transition: ease 0.5s;
}
.portfolio .part .details .name{
  font-size: 25px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.portfolio .part .details a{
  background: #fff;
  color: #0f3285;
  text-decoration: none;
  padding: 5px 30px;
  font-size: 18px;
  border-radius: 10px;
}

.connect-with-us{
    background-color: #0f3285;
    background-image: url(../../public/images/portfolio/strip-bg.png);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    color: #fff;
    padding: 30px;
    margin-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: left;
}
.connect-with-us .content .head{
  font-size: 30px;
  color: #fff;
}
.connect-with-us .btn-group a{
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 2rem;
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  margin-right: 1rem;
  transition: ease 0.5s;
  text-wrap: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.connect-with-us .btn-group a:hover{
  background: #143382;
  color: #fff;
  border: 1px solid #fff;
}

@media screen and (max-width: 1200px) {
  .connect-with-us .btn-group{
    flex-direction: column;
    gap: 10px;
  }
}
@media screen and (max-width: 767px) {
  .custom-banner .banner-head{
    font-size: 2rem;
  }
  .connect-with-us{
    flex-wrap: wrap;
    text-align: center;
  }
  .connect-with-us .btn-group{
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
}