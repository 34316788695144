.sitemap-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.sitemap-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.sitemap-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}


.sitemap-sec{
    padding: 6rem 0;
}
.sitemap-sec .head{
    font-size: 25px;
    border: 1px solid #0f3285;
    padding: 10px 20px;
    margin-bottom: 0;
    background: #0f3285;
    color: #fff;
}
.sitemap-sec ul{
    list-style: none;    
    box-shadow: 0 0px 2px 0 #0f3285;
    padding: 20px;
    display: inline-block;
    margin-bottom: 3rem;
    width: 100%;
}
.sitemap-sec ul li{
    float: left;
    width: 33%;
    position: relative;
    margin-bottom: 0.5rem;
}
.sitemap-sec ul li:before{
    content: ">";
    color: #0f3285;
    font-family: monospace;
    font-weight: 700;
    font-size: 20px;
}
.sitemap-sec ul li a{
    text-decoration: none;
    color: #000;
    transition: ease 0.3s;
}
.sitemap-sec ul li a:hover{
    color: #0f3285;
    padding-left: 7px;
}



/*--responsive --*/
@media screen and (max-width: 991px) {
    .sitemap-sec ul li{
        width: 50%;
    }
}
@media screen and (max-width: 768px) {
    .sitemap-banner .banner-head{
        font-size: 2rem;
    }
    .sitemap-sec ul li{
        width: 100%;
    }
}
