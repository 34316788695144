
.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.vision{
    padding: 8rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.vision .hire-btn{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.vision .hire-btn:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.why-us{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.why-us .head{
    text-align: center;
    margin-bottom: 3rem;
}
.why-us .part{
    background: #f8faf9;
    padding: 26px;
    border-radius: 20px;
    margin-bottom: 0;
    height: 100%;
}
.why-us .part .icon{
    margin-bottom: 1rem;
}
.why-us .part .content h4{
    font-size: 22px;
}
.why-us .col-lg-4:nth-child(1) .part{
    background: #f1f5ff;
}
.why-us .col-lg-4:nth-child(2) .part{
    background: #fff6ed;
}
.why-us .col-lg-4:nth-child(3) .part{
    background: #ffedf9;
}
.why-us .col-lg-4:nth-child(4) .part{
    background: #f5f5f5;
}
.why-us .col-lg-4:nth-child(5) .part{
    background: #ffeeff;
}
.why-us .col-lg-4:nth-child(6) .part{
    background: #fffae5;
}


.technologie{
    background: #f8faf9;
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.technologie .head{
    text-align: center;
    margin-bottom: 3rem;
}
.technologie .icons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    padding: 20px 10px;
}
.technologie .icons img{
    width: 75px;
    height: 75px;
}
.technologie .icons h4{
    font-size: 18px;
    margin-bottom: 0;
}

.our-company-csd{
    background: #f8faf9;
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.our-company-csd .head{
    text-align: center;
    margin-bottom: 3rem;
}
.our-company-csd .icons{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
}
.our-company-csd .icons:hover{
    -webkit-box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
    box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
}
.our-company-csd .icons p{
    font-size: 18px;
    color: #141d38;
    margin-bottom: 0;
}
.our-company-csd .icons .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}
.our-company-csd .icons .icon img {
    width: 80px;
    height: 80px;
    transition: 0.4s ease-in-out;
    margin-left: 10px;
    transform: scale(0.8);
}
.our-company-csd .icons:hover .icon img {
    transform: scale(1);
}


/*--mobile responsive --*/

@media screen and (max-width: 575px) {
    .vision{
        text-align: center;
    }
    .connect{
        padding: 80px 0;
    }
    .why-us .part{
        text-align: center;
    }
}