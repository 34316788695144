
.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.our-fetaures{
    background: #f4f9fd;
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
}
.our-fetaures::after, .our-fetaures::before{
    content: "";
    background: #0f3285;
    height: 400px;
    width: 400px;
    position: absolute;
    left: -300px;
    top: 10%;
    border-radius: 50%;
    z-index: 0;
    opacity: 0.05;
}
.our-fetaures::before{
    top: 20%;
}
.our-fetaures .head{
    margin-bottom: 3rem;
}
.our-fetaures .part{
    background: #ffffff;
    padding: 50px 47px;
    padding-right: 70px;
    -webkit-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -moz-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    border-top: 3px solid transparent;
    transition: ease 0.3s;
}
.our-fetaures .part:hover{
    border-top: 3px solid #023cfd;
}
.our-fetaures .part .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 2rem;
    width: 80px;
    height: 80px;
    line-height: 66px;
    text-align: center;
    margin-bottom: 2rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-image: -moz-linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    background-image: -webkit-linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    background-image: -ms-linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    background-image: linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    -webkit-box-shadow: 0px 20px 16px 0px rgba(2, 60, 253, 0.16);
    -moz-box-shadow: 0px 20px 16px 0px rgba(2, 60, 253, 0.16);
    box-shadow: 0px 20px 16px 0px rgba(2, 60, 253, 0.16);
}
.our-fetaures .part:hover .icon{
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
}
.our-fetaures .part .small-head{
    font-size: 22px;
    font-weight: 600;
    color: #021937;
    margin-bottom: 13px;
}

.work-process{
    padding: 6rem 0;
}
.work-process .head{
    margin-bottom: 3rem;
}
.work-process .part .small-head {
    font-size: 22px;
    font-weight: 600;
    color: #021937;
    margin-bottom: 13px;
}
.work-process .part ul{
    list-style: none;
    padding: 0;
}
.work-process .part ul li{
    font-size: 17px;
    color: #474554;
    font-weight: 500;
    position: relative;
    margin-bottom: 10px;
}
.work-process .part ul li i{
    color: rgba(16, 181, 13, 0.433);
    margin-right: 0.5rem;
}
.work-process .part ul li:hover i{
    color: #11b50d;
}
.work-process .part ul li:hover{
    color: #11b50d;
    cursor: pointer;
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 6rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.tracking{
    padding: 6rem 0;
    min-height: 100vh;
}
.tracking ul{
    list-style: none;
    padding: 0;
}
.tracking ul li{
    font-size: 17px;
    color: #737887;
    font-weight: 500;
    position: relative;
    margin-bottom: 10px;
}
.tracking ul li i{
    color: #11b50d;
    margin-right: 0.3rem;
}


.our-company-sec-mobile{
    background: #f4f9fd;
padding: 6rem 0 ;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.our-company-sec-mobile .head{
    text-align: center;
    margin-bottom: 4rem;
}
.our-company-sec-mobile .icons{
    text-align: center;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border: 1px solid #ebebeb;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    border-radius: 8px;
    background: #fff;
    height: 100%;
}
.our-company-sec-mobile .icons:hover{
    -webkit-box-shadow: 0px 0px 20px 0px rgba(42, 31, 188, 0.3);
    box-shadow: 0px 0px 20px 0px rgba(42, 31, 188, 0.3);
}
.our-company-sec-mobile .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-sec-mobile .icons .icon{
    height: 90px;
    width: 90px;
    line-height: 90px;
    border-radius: 50%;
    font-size: 48px;
    margin: 0px auto 15px;
    color: #2a1fbc;
    background: -webkit-gradient(left top, right bottom, color-stop(0%, #ffffff), color-stop(100%, #f6f5ff));
    background: -webkit-linear-gradient(-45deg, #ffffff 0%, #f6f5ff 100%);
    background: linear-gradient(135deg, #ffffff 0%, #f6f5ff 100%);
}
.our-company-sec-mobile .icons .icon img {
    transition: 0.4s ease-in-out;
    max-width: 50px;
}
.our-company-sec-mobile .icons:hover .icon {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.app-mobile{
    padding: 6rem 0;
    display: flex;
    align-items: center;
}


.technologie{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
}
.technologie .head{
    text-align: center;
    margin-bottom: 3rem;
}
.technologie .icons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    padding: 20px 10px;
}
.technologie .icons img{
    width: 75px;
    height: 75px;
}
.technologie .icons h4{
    font-size: 18px;
    margin-bottom: 0;
}

.trusted{
    padding: 6rem 0;
}
.trusted .mySwiper3 .swiper-slide img{
    object-fit: cover;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 4px 10px 1px #0f328524;
    margin: 10px 5px;
    transition: ease 0.5s;
    max-height: 90px
}
.trusted .mySwiper3 .swiper-slide:hover img{
    padding: 10px;
}
@media screen and (max-width: 1400px) {
    .our-fetaures .part{
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .work-process .head, .our-fetaures .head, .tracking .head, .tracking .tagline{
        text-align: center;
    }
    .work-process .part{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tracking ul{
        padding: 0 2rem;
    }
    .connect .btn-group{
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
}