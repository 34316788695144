
.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
    text-align: center;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}
.custom-banner .call-btn{
    background: #fff;
    color: #143382;
    border-radius: 2rem;
    padding: 10px 30px !important;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    border: none;
    text-transform: none;
    text-align: center;
    border: 1px solid #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
}
.custom-banner .call-btn:hover{
    color: #fff;
}
.custom-banner .call-btn:before, .custom-banner .call-btn:after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #143382;
    z-index: -1;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    border-radius: 2rem;
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
}
.custom-banner .call-btn:hover::before, .custom-banner .call-btn:hover:after, .custom-banner .call-btn.active::before, .custom-banner .call-btn.active:after {
    border-radius: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.custom-banner .call-btn:before, .custom-banner .call-btn:after {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
}

.our-fetaures{
    background: #f4f9fd;
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
}
.our-fetaures::after, .our-fetaures::before{
    content: "";
    background: #0f3285;
    height: 400px;
    width: 400px;
    position: absolute;
    left: -300px;
    top: 10%;
    border-radius: 50%;
    z-index: 0;
    opacity: 0.05;
}
.our-fetaures::before{
    top: 20%;
}
.our-fetaures .head{
    margin-bottom: 3rem;
}
.our-fetaures .part{
    background: #ffffff;
    padding: 50px 47px;
    padding-right: 70px;
    -webkit-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -moz-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    border-top: 3px solid transparent;
    transition: ease 0.3s;
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.our-fetaures .part:hover{
    border-top: 3px solid #023cfd;
}
.our-fetaures .part .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 2rem;
    width: 80px;
    height: 80px;
    line-height: 66px;
    text-align: center;
    margin-bottom: 2rem;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-image: -moz-linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    background-image: -webkit-linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    background-image: -ms-linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    background-image: linear-gradient(90deg, #023cfd 0%, #0372fe 53%, #03a7ff 100%);
    -webkit-box-shadow: 0px 20px 16px 0px rgba(2, 60, 253, 0.16);
    -moz-box-shadow: 0px 20px 16px 0px rgba(2, 60, 253, 0.16);
    box-shadow: 0px 20px 16px 0px rgba(2, 60, 253, 0.16);
}
.our-fetaures .part:hover .icon{
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
.our-fetaures .part .icon i{
    width: 80px;
}
@keyframes shake {
    10%, 90% {
      -webkit-transform: translate3d(-1px, 0, 0);
              transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      -webkit-transform: translate3d(2px, 0, 0);
              transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      -webkit-transform: translate3d(-4px, 0, 0);
              transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      -webkit-transform: translate3d(4px, 0, 0);
              transform: translate3d(4px, 0, 0);
    }
}
.our-fetaures .part .small-head{
    font-size: 22px;
    font-weight: 600;
    color: #021937;
    margin-bottom: 13px;
}

.welcome{
    padding: 6rem 0;
}
.welcome .content .part{
    display: flex;
    gap: 1rem;
}
.welcome .content .part .icon{
    height: 80px;
    width: 80px;
}
.welcome .content .part .icon img{
    max-width: 60px;
}
.welcome .content .part .part-content .small-head{
    font-size: 20px;
    color: #141d38;
    font-weight: 600;
    margin-bottom: 0.4rem;
}

.work-process{
    background: #f4f9fd;
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
    text-align: center;
}
.work-process .head{
    margin-bottom: 3rem;
}
.work-process .part .small-head {
    font-size: 20px;
    font-weight: 600;
    color: #021937;
    margin-bottom: 5px;
}
.work-process .part{
    text-align: left;
    background: #fff;
    padding: 10px 70px 10px 10px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -moz-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    z-index: 2;
}
.work-process .part::after{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 60px;
    font-weight: 600;
    color: #dddddd75;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: -1;
}
.work-process .col-lg-4:nth-child(1) .part::after{
    content: "01";
}
.work-process .col-lg-4:nth-child(2) .part::after{
    content: "02";
}
.work-process .col-lg-4:nth-child(3) .part::after{
    content: "03";
}
.work-process .col-lg-4:nth-child(4) .part::after{
    content: "04";
}
.work-process .col-lg-4:nth-child(5) .part::after{
    content: "05";
}

.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 6rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.technologie{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
}
.technologie .head{
    text-align: center;
    margin-bottom: 3rem;
}
.technologie .icons{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    padding: 20px 10px;
}
.technologie .col-lg-3:nth-child(1) .icons{
    background: #ecf8d5;
}
.technologie .col-lg-3:nth-child(2) .icons{
    background: #dffbfc;
}
.technologie .col-lg-3:nth-child(3) .icons{
    background: #fce4e4;
}
.technologie .col-lg-3:nth-child(4) .icons{
    background: #fcf5df;
}
.technologie .col-lg-3:nth-child(5) .icons{
    background: #f6defe;
}
.technologie .col-lg-3:nth-child(6) .icons{
    background: #e4fce6;
}
.technologie .col-lg-3:nth-child(7) .icons{
    background: #ecf8d5;
}
.technologie .col-lg-3:nth-child(8) .icons{
    background: #dffbfc;
}
.technologie .icons img{
    width: 75px;
    height: 75px;
}
.technologie .icons h4{
    font-size: 18px;
    margin-bottom: 0;
}

.app-mobile{
    padding: 6rem 0;
    display: flex;
    align-items: center;
}

.our-company-sec-mobile{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.our-company-sec-mobile .head{
    text-align: center;
    margin-bottom: 4rem;
}
.our-company-sec-mobile .icons{
    text-align: center;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border: 1px solid #ebebeb;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    border-radius: 8px;
    background: #fff;
    height: 100%;
}
.our-company-sec-mobile .icons:hover{
    -webkit-box-shadow: 0px 0px 20px 0px rgba(42, 31, 188, 0.3);
    box-shadow: 0px 0px 20px 0px rgba(42, 31, 188, 0.3);
}

.our-company-sec-mobile .col-lg-3:nth-child(1) .icons{
    background: #ecf8d5;
}
.our-company-sec-mobile .col-lg-3:nth-child(2) .icons{
    background: #dffbfc;
}
.our-company-sec-mobile .col-lg-3:nth-child(3) .icons{
    background: #fce4e4;
}
.our-company-sec-mobile .col-lg-3:nth-child(4) .icons{
    background: #fcf5df;
}
.our-company-sec-mobile .col-lg-3:nth-child(5) .icons{
    background: #f6defe;
}
.our-company-sec-mobile .col-lg-3:nth-child(6) .icons{
    background: #e4fce6;
}
.our-company-sec-mobile .col-lg-3:nth-child(7) .icons{
    background: #ecf8d5;
}
.our-company-sec-mobile.col-lg-3:nth-child(8) .icons{
    background: #dffbfc;
}

.our-company-sec-mobile .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-sec-mobile .icons .icon{
    height: 90px;
    width: 90px;
    line-height: 90px;
    border-radius: 50%;
    font-size: 48px;
    margin: 0px auto 15px;
    color: #2a1fbc;
    background: #ffffff;
}
.our-company-sec-mobile .icons .icon img {
    transition: 0.4s ease-in-out;
    max-width: 50px;
}
.our-company-sec-mobile .icons:hover .icon {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}


.trusted{
    padding: 6rem 0;
}
.trusted .images{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.trusted .images img{
    transform: scale(0.8);
    transition: ease 0.3s;
    box-shadow: 0 4px 5px #ddd;
    border-radius: 10px;
}
.trusted .images img:hover{
    transform: scale(1);
}


@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .our-fetaures .part{
        flex-direction: column;
    }
    .work-process .head, .our-fetaures .head, .tracking .head, .tracking .tagline{
        text-align: center;
    }
    .work-process .part{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .tracking ul{
        padding: 0 2rem;
    }
    .connect .btn-group{
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
}