.custom-banner {
  height: auto;
  background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
  background-repeat: no-repeat;
  background-position: right center;
  padding: 8rem 0;
  color: #fff;
  display: flex;
  align-items: center;
  scroll-margin: 100px;
}
.custom-banner .banner-head {
  color: #fff;
  text-transform: capitalize;
}
.custom-banner .banner-head span {
  display: block;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 1rem;
}

.gallery {
  padding: 60px 0;
}

.gallery .info-part {
  border-radius: 10px;
  border: 1px solid #e2e4e7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(226, 228, 231, 0.25);
  padding: 30px;
}

.gallery .info-part .head {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.417;
    color: #141d38;
    text-decoration: none;
}

.gallery .info-part p {
  margin-bottom: 20px;
}

.gallery .info-part ul {
  list-style: none;
  padding: 0;
  padding-top: 20px;
  border-top: 1px solid #e2e5e9;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.gallery .info-part ul li {
  display: flex;
  align-items: flex-start;
}

.gallery .info-part ul li i {
  color: #0f3285;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.gallery .info-part ul li a {
  text-decoration: none;
}

.gallery .info-part ul li a:hover {
  color: #0f3285;
}

.gallery .all-photos-sec {
  border-radius: 10px;
  border: 1px solid #e2e4e7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(226, 228, 231, 0.25);
  padding: 30px;
  margin: 20px 0;
}

.gallery .all-photos-sec .head-part {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.gallery .all-photos-sec .head-part .head {
  margin-bottom: 7px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.417;
  color: #141d38;
  text-decoration: none;
}

.gallery .all-photos-sec .head-part button {
  color: #0064d1;
  border: none;
  background: #fff;
}

.gallery .all-photos-sec .all-photo {
  overflow: hidden;
  border-radius: 10px;
  margin-top: 15px;
}

.gallery .profile-tab {
  border-radius: 10px;
  border: 1px solid #e2e4e7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(226, 228, 231, 0.25);
  padding: 30px;
}

.gallery .profile-tab .head-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 30px;
}

.gallery .profile-tab .head-top .profile-photo {
  width: 100%;
  max-width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #0f3285;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery .profile-tab .head-top p{
  font-size: 20px;
  font-weight: 700;
  line-height: 1.417;
  color: #141d38;
}

.gallery .profile-tab .head-top .profile-photo img {
  object-fit: scale-down;
}

.gallery .profile-tab .head-top input {
  border-radius: 30px;
  background: #f0f2f5;
  min-height: 60px;
  width: 100%;
  border: none;
  padding: 0 30px;
  outline: none;
}

/* .gallery .profile-tab .head-top input::placeholder {
  color: #65686c;
} */

.gallery .profile-tab .photo-tab {
  border-top: 1px solid #e2e5e9;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.gallery .profile-tab .photo-tab a {
  text-decoration: none;
  position: relative;
  padding-left: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery .profile-tab .photo-tab a::after {
  content: "";
  height: 24px;
  width: 24px;
  background: url(../../public/images/gallery/photo.png) center center no-repeat;
  position: absolute;
  left: 0;
}

.gallery .posts .post {
  border-radius: 10px;
  border: 1px solid #e2e4e7;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(226, 228, 231, 0.25);
  padding: 30px;
  margin-top: 20px;
}

.gallery .posts .post .head-top {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 30px;
}

.gallery .posts .post .head-top .profile-photo {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background: #0f3285;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery .posts .post .head-top .profile-photo img {
  object-fit: scale-down;
}

.gallery .posts .post .head-top p {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.417;
    color: #141d38;
    text-decoration: none;
}

.gallery .posts .post .discription {
  margin-bottom: 15px;
}

.gallery .posts .post .discription span {
  color: #0064d1;
}

.gallery .posts .post .discription a {
  text-decoration-line: underline;
}

.gallery .posts .post .discription button {
  color: #000;
  border: none;
  outline: none;
  background: transparent;
}
.gallery-item{
  height: 100%;
}

.gallery-item img{
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: static;
}

.all-photos {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.thumbnail {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.post-photos {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
}

.post-photos .post-photo {
  width: 50%;
  height: 225px;
  padding: 2px;
}

.post-photos .post-photo img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: static;
}

.post-photos .post-image-total {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 222px;
  width: 49%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  pointer-events: none; 
}

.slider {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 9;
}

.slider .slider-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.slider .slider-content img {
  max-width: 90%;
  max-height: 80%;
}

.slider .close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: white;
  cursor: pointer;
}

.slider .prev, .slider .next {
  position: absolute;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transform: translateY(-50%);
}

.slider .prev {
  left: 20px;
}

.slider .next {
  right: 20px;
}

.slider h2 {
  margin-top: 20px;
  text-transform: capitalize;
}
