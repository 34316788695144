.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.custom-banner .available-job{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    color: #ffffff;
    text-shadow: 2px 3px 0px #001954;
    font-size: 35px;
    font-weight: 700;
}
.custom-banner .available-job span{
    position: relative;
    padding: 0 7px;
}
.custom-banner .available-job span:after{
    content: "";
    background-color: #3C65F5;
    height: 25px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -6px;
    z-index: 0;
    opacity: 0.1;
}

.custom-banner .banner-search{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem 0rem;
}
.custom-banner .banner-search .back-sec{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
    background: #07256c;
    padding: 1rem;
    border-radius: 1rem ;
}
.custom-banner .banner-search .back-sec input{
    background: #fff;
    color: #143382;
    box-shadow: none;
    border: none;
    outline: none;
    height: 100%;
    padding: 10px;
    min-width: 280px;
    border-radius: 2rem;
}
.custom-banner .banner-search .back-sec input:focus{
    box-shadow: none;
    border: none;
    outline: none;
}
.custom-banner .banner-search .back-sec input::placeholder{
    color: #1433828a;
}
.custom-banner .banner-search .back-sec select{
    background: #fff;
    color: #143382;
    border-radius: 2rem;
    padding: 12px 10px 12px 20px;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    border: none;
    text-transform: none;
    text-align: center;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    margin: 0 10px;
}
.custom-banner .banner-search .back-sec button{
    background: #fff;
    color: #143382;
    border-radius: 2rem;
    padding: 10px 30px;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    border: none;
    text-transform: none;
    text-align: center;
    border: 1px solid #fff;
}
  
.filter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    background: #ffffff;
    border: 2px solid #0f3285;
    padding: 1rem;
    border-radius: 1rem;
    position: relative;
    top: -2rem;
    box-shadow: 0 -2px 5px #ffffff4d;
}
.filter .name{
    color: #141d38;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}
.filter label{
    color: #141d38;
}
.filter select{
    outline: none;
    box-shadow: none;
    background: #fff;
    color: #143382;
    padding: 10px;
    margin: 0px 5px;
    border-radius: 2rem;
}
#form{
    display: inline-block;
    width: 100%;
    padding: 2rem;
    border-radius: 8px;
    border: 1px solid #E0E6F7;
    overflow: hidden;
    margin-bottom: 24px;
    position: relative;
    background: #F8FAFF;
}
.job-list .job-item, #jobDetailsSection .details, #jobDetailsSection .apply-form{
    display: inline-block;
    width: 100%;
    padding: 2rem;
    border-radius: 8px;
    border: 1px solid #E0E6F7;
    overflow: hidden;
    margin-bottom: 24px;
    position: relative;
    background: #F8FAFF;
}
#form .head{
    color: #141d38;
    text-align: center;
    font-size: 30px;
    margin-bottom: 2rem;
    font-weight: 600;
}
#jobDetailsSection .apply-form .head{
    color: #141d38;
    text-align: center;
    font-size: 30px;
    margin-bottom: 2rem;
    font-weight: 600;
}

.apply-form .form-control, .form-possition .form-control:focus{
    outline: none;
    box-shadow: none;
}

.apply-form #captcha-container div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    gap: 10px;
}

.apply-form .drop-resume{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    padding: 1rem;
    border-radius: 7px;
    gap: 10px;
}
.apply-form .drop-resume a{
    font-size: 19px;
    color: #0f3285;
    text-decoration: none;
}


.culture{
    padding: 8rem 0;
    background-color: #fff;
    min-height: 100vh;
}

.culture .imgarea{
    text-align: center;
}

.culture .content-part .part{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    border: 1px solid #ddd;
    border-radius: 7px;
    margin-bottom: 1rem;
    padding: 1rem;
}



.consultation{
    padding: 8rem 0;
    background-color: #0f3285; 
}
.consultation .head, .consultation .head span{
    color: #fff;
}
.consultation .part{
    background-color: #fff; 
    text-align: center;
    padding: 3rem 2rem;
    height: 100%;
    border-radius: 7px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}
.consultation .part .icon{
    width: 90px;
    height: 90px;
    line-height: 90px;
    border-radius: 90px;
    text-align: center;
    border: 1px solid #0f3285;
    background: #0f32851a;
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    font-size: 30px;
    color: #0f3285;
}
.consultation .part p, .consultation .part a{
    margin: 0;
    color: #737887;
    text-decoration: none;
}

.job-list .job-item:nth-child(1) , .experience .job-item:nth-child(7) {
    background: #f1f5ff;
}
.job-list .job-item:nth-child(2) , .experience .job-item:nth-child(8) {
    background: #fff6ed;
}
.job-list .job-item:nth-child(3) , .experience .job-item:nth-child(9) {
    background: #ffedf9;
}
.job-list .job-item:nth-child(4) , .experience .job-item:nth-child(10) {
    background: #f5f5f5;
}
.job-list .job-item:nth-child(5) , .experience .job-item:nth-child(11) {
    background: #ffeeff;
}
.job-list .job-item:nth-child(6) , .experience .job-item:nth-child(12) {
    background: #fffae5;
}