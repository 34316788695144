/* banner */
.banner{
    background: url(../../public/images/custom-developemt.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 2rem 0;
    min-height: 90vh;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
    animation: leftright 2s linear 0s infinite alternate;
}
@keyframes leftright {
    from {background-position: 100% center;}
    to {background-position: 90% center;}
}
.banner2{
    background: url(../../public/images/web-development.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
}
.banner3{
    background: url(../../public/images/application-development.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
}
.banner4{
    background: url(../../public/images/ecommerce-developemt.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
}
.banner5{
    background: url(../../public/images/hire-developer.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
}
.banner .banner-head{
    font-size: 3.3rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}
.banner .tagline{
    font-size: 18px;
    /* text-transform: capitalize; */
    color: rgba(255, 255, 255, 0.856);
    margin-bottom: 2rem;
}
.banner .btn-group{
    margin-bottom: 2rem;
}
.banner .btn-group a{
    background: #fff;
    color: #143382;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: flex;
    align-items: center;
}
.banner .btn-group a:nth-child(1){
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 2rem;
}
.banner .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}
.banner .btn-group a:nth-child(1):hover{
    background: #fff;
    color: #143382;
    border: 1px solid #fff;
}
.carousel-indicators {
    bottom: 5%;
}
.carousel-indicators [data-bs-target]{
    height: 15px;
    width: 15px;
    border: 5px solid #fff;
    background: #ffffff33;
    border-radius: 50%;
}
/* pp-pos */
.pp-pos {
    position: fixed;
    top: 20%;
    left: 2%;
    z-index: 9;
    max-width: 105px;
    overflow: hidden;
}

.pp-pos .list {
    position: relative;
    text-decoration: none;
    color: #fff;
    padding: 0.5rem 0 2rem 0;
}

.pp-pos .list:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 20%;
    background: #ffffff; /* Default background color */
    border-radius: 2px;
    transition: ease 0.3s;
}

.pp-pos .list.active:after,
.pp-pos .list:hover:after {
    height: 3px;
    width: 50%;
    background: #0f3285; /* Default active background color */
}

.pp-pos .list.active-blue:after {
    background: #fff; /* Blue background for active blue items */
}

.pp-pos .list.active-white:after {
    background: #0f3285; /* White background for active white items */
}

.pp-pos .list span {
    opacity: 0;
    text-transform: capitalize;
    color: #fff; /* Default text color */
    font-size: 14px;
    transition: ease 0.3s;
}

.pp-pos .list.active span {
    opacity: 1;
}

.pp-pos .list:hover span {
    opacity: 1;
}
/* design and develop */
.design-dev{
    padding-top: 8rem;
    min-height: 90vh;
}
.design-dev .choose-feature {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    gap: 35px;
    max-width: 410px;
}
.design-dev .choose-feature .box-icon {
    position: relative;
    z-index: 2;
    min-width: 50px;
}
.design-dev .list li:hover{
    box-shadow: 0px 3px 0px 1px #143382;
    border: 1px solid #143382;
}
.design-dev .choose-feature .box-icon:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: #E8ECF6;
    border-radius: 999px;
    z-index: -1;
}
.design-dev .choose-feature .box-icon img {
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    margin-left: 10px;
}
.design-dev .choose-feature .box-title {
    margin-bottom: 7px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.417;
    color: #141d38;
    text-decoration: none;
    cursor: pointer;
}
.design-dev .choose-feature:hover .box-icon img {
    -webkit-transform: scale(-1) rotate(180deg);
    -ms-transform: scale(-1) rotate(180deg);
    transform: scale(-1) rotate(180deg);
}
.design-dev .cunsult-strip{
    background-image: url(../../public/images/strip-bg.png);
    padding: 3rem 2rem;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    margin-top: 80px;
}
.design-dev .cunsult-strip .cta-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.cta-content .cta-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 25px;
    border-right: 1px solid rgba(255, 255, 255, 0.15);
    padding-right: 80px;
    margin-right: 80px;
    min-width: 440px;
}
.cta-content .cta-wrapper .cta-icon {
    width: 90px;
    height: 90px;
    line-height: 90px;
    border-radius: 90px;
    text-align: center;
    border: 1px solid rgba(255, 255, 255, 0.22);
    background: rgba(255, 255, 255, 0.05);
    -webkit-backdrop-filter: blur(11px);
    backdrop-filter: blur(11px);
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}
.cta-content .cta-wrapper .header-info_link a{
    font-size: 30px;
    font-weight: 600;
    color: #fff;
    letter-spacing: -0.3px;
    margin-bottom: 0;
    text-decoration: none;
}
.cta-content .title-area {
    margin-right: 64px;
}

/* about us */
.about-sec{
    padding: 8rem 0;
    min-height: 90vh;
}
/* .about-sec .about-area {
    border: 1px solid #E3E7F0;
    padding: 80px;
    border-radius: 30px;
} */
.about-sec .about-area .check-list{
    list-style: none;
    padding: 0;
    line-height: 1.8;
    margin: 2rem 0;
}
.about-sec .about-area .check-list li {
    display: flex;
    align-items: flex-start;
    gap: 7px;
}
.about-sec .about-area .check-list i{
   color: #143382;
   margin-top: 7px;
}
.img-box {
    position: relative;
    z-index: 2;
    text-align: right;
}
.img-box .imgarea {
    position: relative;
    text-align: right;
    z-index: 2;
    border-radius: 30px;
}
.img-box .imgarea img{
    border-radius: 30px;
}
.img-box .experience {
    position: absolute;
    bottom: 80px;
    left: 0px;
    z-index: 2;
    padding: 30px 30px 30px 40px;
    background: #143382;
    border-radius: 25px 0px 0px 25px;
    width: 340px;
    color: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    font-family: var(--title-font);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
}
.img-box .experience .experience-year {
    font-size: 54px;
    font-weight: 700;
    letter-spacing: -1.08px;
    color: #fff;
    margin-bottom: 0px;
}

/* top-notch design */
.top-notch{
    background: #143382;
    position: relative;
    min-height: 90vh;
    overflow: hidden;
}
.top-notch .head, .top-notch .head span{
    color: #fff;
}
.top-notch .content-part{
    padding: 5rem 0;
}
.top-notch .news-images{
    position: absolute;
    width: 50%;
    right: 0;
    top: 0;
    display: flex;
    flex-wrap: wrap;
}
.top-notch .news-images img{
    display: flex;
    object-fit: cover;
    width: 50%;
}
.top-notch .list{
    list-style: none;
    margin-top: 2rem;
    padding: 0;
    display: inline-block;
}
.top-notch .list li{
    padding-right: 3rem;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    position: relative;
    font-size: 19px;
    border-bottom: 1px solid #ddd;
    transition: ease 0.5s;
}
.top-notch .list li::before{
    content: ">>";
    position: absolute;
    top: -2px;
    right: 20px;
    font-family: monospace;
    font-size: 30px;
    transition: ease 0.5s;
    letter-spacing: -27px;
    color: #fff;
}
.top-notch .list li:hover{
    padding-left: 10px;
}
.top-notch .list li:hover::before{
    right: 10px;
    color: #fff;
}
.top-notch .list li a{
    text-decoration: none;
    color: #fff;
}

/* career */
.career{
    padding: 8rem 0;
    background: #f8faf9;
    min-height: 90vh;
}
.career .img-text-group{
    margin-top: 80px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 50px;
}
.career .img-text-group .main-image{
    object-fit: cover;
    width: 100%;
    height: 60vh;
    border-radius: 50px;
    transition: ease 0.5s;
}
.career .img-text-group:hover .main-image{
    transform: scale(1.2);
    
}
.career .img-text-group .content{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    box-shadow: 0 3px 6px 3px #14338273;
    margin-bottom: 20px;
    border-radius: 30px;
    padding: 1rem 2rem;
    gap: 3rem;
    position: absolute;
    bottom: 20px;
    right: 30px;
    flex-wrap: wrap;
}
.career .img-text-group .content .part:nth-child(1){
    border-right: 1px solid #ddd;
    padding-right: 1rem;
}
.career .img-text-group .content .part:nth-child(2){
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 1rem;
}

/* client review */
.client-review{
    padding: 8rem 0;
    background: #fff;
    position: relative;
    z-index: 2;
    /* min-height: 90vh; */
}
.client-review::before{
    content: "";
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    position: absolute;
    top: 0;
    width: 100%;
    height: 50vh;
    z-index: -1;
}
.client-review .head, .client-review .head span, .client-review .tagline{
    color: #fff;
}
.client-review .review-content{
    padding: 2rem;
    box-shadow: 0px 0px 2px 0px #143382;
    border-radius: 0.4rem;
    background: #fff;
    margin: 5px;
}
.client-review .review-content .user{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.client-review .review-content .user .user-details{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
}
.client-review .review-content .user span{
    display: inline-block;
    text-align: center;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    margin: 0;
    padding: 0;
    font-size: 6rem;
    font-weight: 600;
    font-family: sans-serif;
    color: #16356c;
}
.client-review .review-content .rate-date{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
.client-review .review-content .rate-date .rate span{
    display: inline-block;
    height: 18px;
    width: 18px;
    background: url(../../public/images/star.png);
    background-repeat: no-repeat;
}
.client-review .review-content .rate-date .rate .t-rate{
    font-size: 22px;
    font-weight: 600;
    padding-left: 10px;
}
.client-review .review-content .rate-date .date{
    margin: 0;
    color: #8d8d8d;
}
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-pagination-bullet {
    padding: 5px;
    border: 7px solid white;
    background-color: #0f3285;
    opacity: 1 !important;
}
.swiper-pagination-bullet-active {
    box-shadow: 0 0 0 2px #0f3285;
    background: #0f3285;
}

/*--why-choose-us--*/
.why-choose-us{
    padding: 8rem 0;
    /* background: #143382; */
    background: linear-gradient(45deg, #143382e3, #143382b3), url(../../public/images/banner-bg-2.png) no-repeat fixed;
    /* min-height: 90vh; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.why-choose-us .head, .why-choose-us .head span{
    color: #fff;
}
.why-choose-us .part{
    text-align: center;
    background: #10338575;
    color: #fff;
    padding: 3rem 2rem;
    height: 100%;
    border-radius: 7px;
    margin-top: 2rem;
    border: 1px dashed #ffffff40;
    transition: ease 0.3s;
}
.why-choose-us .part h3{
    margin: 15px 0;
    color: #fff;
}
.why-choose-us .part img{
    background: #fff;
    border-radius: 50%;
    padding: 15px;
    border: 1px dashed #0f3285;
    transition: ease 0.3s;
}
.why-choose-us .part:hover img{
    transform: rotate(360deg);
}
.why-choose-us .part:hover{
    border: 1px dashed #fff;
}

/* collaboration */
.collaboration{
    padding: 8rem 0;
    background: #f8faf9;
    min-height: 90vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.collaboration .part{
    background: #fff;
    border: 1px dashed #1c3a82;
    border-radius: 7px;
    padding: 1rem;
    margin-bottom: 1rem;
    min-height: 100%;
}
.collaboration .col-xl-4 .part{
    min-height: 48%;
}

.our-clients{
    display: flex;
    align-items: center;
    background: #143382;
    color: #fff;
    padding: 1rem;
    border-radius: 7px;
    position: relative;
    z-index: 2;
}
.our-clients::after{
    content: "";
    height: 30px;
    width: 30px;
    background: #143382;
    border-radius: 7px;
    position: absolute;
    right: -12px;
    top: 35px;
    transform: rotate(45deg);
    z-index: -1;
}
.mySwiper3 .swiper-slide img{
    object-fit: cover;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 4px 10px 1px #0f328524;
    margin: 10px 5px;
}
/* solutions */
.solutions{
    background: #001850;
    padding: 5rem;
    text-align: center;
    color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    height: auto;
}
.solutions::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: -50%;
    height: 200%;
    width: 200%;
    background: #0f3285;
    border-radius: 50%;
    z-index: -1;
    box-shadow: inset 0 0px 0px 2px #fff;
}

/* Responsive */
@media screen and (max-width: 1400px) {
    .banner .banner-head {
        font-size: 2.5rem;
    }
    .banner{
        background-color: #0f3285;
        background-size: 35rem;
    }
    .banner .btn-group {
        margin-bottom: 5rem;
    }
    .pp-pos .list {
        padding: 0.5rem 0 1.5rem 0;
    }
    .top-notch .content-part {
        padding: 3rem 0;
    }
    .top-notch .list{
        margin-top: 1rem;
    }
    .top-notch .list li {
        margin-bottom: 1rem;
        font-size: 17px;
    }
    .top-notch .list li::before {
        font-size: 20px;
        letter-spacing: -20px;
    }
}

@media screen and (max-width: 1200px) {
    .design-dev .cunsult-strip .cta-content {
        display: block;
    }
    .cta-content .cta-wrapper{
        border-right: none;
    }
    
    .collaboration .d-flex.align-items-end{
        display: block !important;
    }
    .collaboration .part{
        display: flex;
        flex-direction: row;
        gap: 1rem;
        min-height: 90%;
        margin-bottom: 1rem;
    }
    .collaboration .col-xl-4 .part {
        min-height: 45%;
    }
    .collaboration .part img{
        object-fit: contain;
    }
    .about-sec .img-box{
        margin-top: 2rem;
    }
    .top-notch .news-images{
        position: relative;
        width: 100%;
    }
    .career .img-text-group .content .part:nth-child(1){
        border-right: none;
    }
    
}

@media screen and (max-width: 991px) {
    .design-dev, .about-sec, .career, .why-choose-us, .collaboration, .solutions {
        padding: 4rem 0;
    }
    .banner{
        background: linear-gradient(274deg, #0f3285c7, #0f3285), url(../../public/images/custom-developemt.png) no-repeat right center;
    }
    .banner2{
        background: linear-gradient(274deg, #0f3285c7, #0f3285), url(../../public/images/web-development.png) no-repeat right center;
    }
    .banner3{
        background: linear-gradient(274deg, #0f3285c7, #0f3285), url(../../public/images/application-development.png) no-repeat right center;
    }
    .banner4{
        background: linear-gradient(274deg, #0f3285c7, #0f3285), url(../../public/images/ecommerce-developemt.png) no-repeat right center;
    }
    .banner5{
        background: linear-gradient(274deg, #0f3285c7, #0f3285), url(../../public/images/hire-developer.png) no-repeat right center;
    }
    .pp-pos{
        display: none;
    }
    
    .banner .btn-group{
        flex-wrap: wrap;
        gap: 1rem;
    }
    .design-dev .cunsult-strip{
        margin-top: 2rem;
    }
    .top-notch .news-images{
        position: relative;
        margin-top: 2rem;
        width: 100%;
    }
    .career .img-text-group .content1{
        position: relative;
        width: 100%;
    }
    .career .img-text-group .content2{
        position: relative;
        width: 100%;
        flex-wrap: nowrap;
        align-items: flex-start;
        right: 0;
        padding: 2rem;
    }  
    .career .img-text-group .content {
        padding: 1rem 1rem;
        bottom: auto;
        right: auto;
    }
    
}

/*--mobile responsive --*/

@media screen and (max-width: 575px) {
    .banner{
        text-align: center;
    }
    .banner .banner-head {
        font-size: 2rem;
    }
    .banner .btn-group {
        justify-content: center;
        display: flex;
    }
    .banner .btn-group {
        margin-bottom: 4rem;
    }
    .banner .btn-group a:nth-child(1){
        margin-left: 10px;
        margin-right: 10px;
    }
    .design-dev .col-md-6{
        justify-content: center;
        display: flex;
    }
    .design-dev .choose-feature{
        display: block;
        text-align: center;
    }
    .design-dev .choose-feature .box-icon:before{
        left: auto;
    }
    .design-dev{
        text-align: center;
    }
    .design-dev .list{
        justify-content: center;
    }
    .cta-content .cta-wrapper{
        flex-direction: column;
        margin: 0;
        padding: 0;
        min-width: auto;
    }
    .cta-content .title-area{
        margin: 0;
    }
    .top-notch{
        text-align: center;
    }
    .top-notch .list li{
        border: 1px solid #ddd;
        padding: 1rem;
    }
    .top-notch .list li::before{
        display: none;
    }
    .top-notch .news-images img{
        width: 100%;
    }
    .career .img-text-group .main-image{
        display: none;
    }
    .career .img-text-group .content{
        text-align: center;
        display: block;
        position: relative;
    }
    .career .img-text-group .content .part{
        display: block !important;
        margin-top: 1rem;
    }
    .client-review .review-content .review{
        text-align: center;
    }
    .client-review::before{
        height: 80vh;
    }
    .collaboration{
        text-align: center;
    }
}