.clients-testimonials-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.clients-testimonials-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.clients-testimonials-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}


.our-clients-ct{
    padding: 6rem 0;
}
.our-clients-ct .clients-logos{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
.our-clients-ct .clients-logos img{
    border: 1px solid #0f32852e;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 #01132c14;
}

.clients-says{
    padding: 6rem 0;
    background: #f8faf9;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.clients-says .reviews {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
}

.clients-says .review-card {
    background: #fff;
    border: 1px solid #0f32852e;
    border-radius: 20px;
    box-shadow: 0 10px 20px 0 #01132c14;
    position: relative;
    transition: .3s ease;
    padding: 20px;
    width: calc(33.333% - 20px);
}

.clients-says .review-card h4 {
    margin: 0 0 10px;
    color: #333;
}

.clients-says .review-card .review {
    position: relative;
    font-size: 17px;
}
.clients-says .review-card .review::before {
    content: '“';
}

.clients-says .review-card .review::after {
    content: '”';
}

.clients-says .review-card .name {
    font-size: 17px;
    border-top: 1px dashed #ddd;
    padding-top: 10px;
}
.clients-says .review-card .name span:nth-last-child(1) {
    font-weight: 600 !important;
    position: relative;
    font-weight: 500;
    position: relative;
    margin-left: 5px;
    padding-left: 10px;
}
.clients-says .review-card .name span:nth-last-child(1):before {
    content: '-';
    position: absolute;
    left: 0;
}
.clients-says .review-btn{
    text-wrap: nowrap;
    align-items: center;
    background: #fff;
    border: 1px solid #000;
    border-radius: 2rem;
    color: #000;
    display: inline-flex;
    font-size: 17px;
    font-weight: 500;
    gap: 10px;
    justify-content: center;
    margin-right: 1rem;
    padding: 10px 30px;
    text-decoration: none;
    text-transform: capitalize;
    transition: .5s ease;
}
.clients-says .review-btn:hover {
    background: #143382;
    border: 1px solid #fff;
    color: #fff;
}


.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.our-company-ct{
    padding: 8rem 0;
    display: flex;
    align-items: center;
    min-height: 90vh;
}
.our-company-ct .head{
    text-align: center;
    margin-bottom: 3rem;
}
.our-company-ct .icons{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 1px 15px 1px #0f328514;
    border-radius: 10px;
    padding: 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
}
.our-company-ct .icons:hover{
   margin-top: -1rem;
}
.our-company-ct .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-ct .icons .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
}
.our-company-ct .icons .icon img {
    max-width: 55px;
}

.app-mobile{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
}
.app-mobile .hire-btn{
    text-wrap: nowrap;
    align-items: center;
    background: #fff;
    border: 1px solid #000;
    border-radius: 2rem;
    color: #000;
    display: inline-flex;
    font-size: 17px;
    font-weight: 500;
    gap: 10px;
    justify-content: center;
    margin-right: 1rem;
    padding: 10px 30px;
    text-decoration: none;
    text-transform: capitalize;
    transition: .5s ease;
}
.app-mobile .hire-btn:hover {
    background: #143382;
    border: 1px solid #fff;
    color: #fff;
}

/*--responsive --*/
@media screen and (max-width: 1200px) {
    .clients-says .review-card {
        width: calc(50% - 20px);
    }
}
@media screen and (max-width: 768px) {
    .clients-testimonials-banner .banner-head{
        font-size: 2rem;
    }
    .clients-says .review-card {
        width: calc(100% - 0px);
    }
    .connect {
        padding: 80px 0;
    }
    .connect  .btn-group {
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
    }
    .app-mobile{
        text-align: center;
    }
}
