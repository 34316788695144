.custom-banner{
  height: auto;
  background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
  background-repeat: no-repeat;
  background-position: right center;
  padding: 8rem 0;
  color: #fff;
  display: flex;
  align-items: center;
  scroll-margin: 100px;
}
.custom-banner .banner-head{
  font-size: 3.5rem;
  line-height: 1.3;
  font-weight: 700;
  color: #fff;
  text-transform: capitalize;
}
.custom-banner .banner-head span{
  font-size: 1.2rem;
  display: block;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 1rem;
  font-weight: 400;
}

.portfolio{
  padding: 4rem 0;
  background-color: #f8faf9;
}
.portfolio .head{
  margin-bottom: 2rem;
}
.portfolio #portfolio-flters {
  list-style: none;
  margin-bottom: 3rem;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  background: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 2rem;
  padding: 10px 30px;
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
  margin-right: 1rem;
  transition: ease 0.5s;
  text-wrap: nowrap;
  display: inline-flex;
  align-items: center;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #143382;
  color: #fff;
  border: 1px solid #fff;
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio .portfolio-item .portfolio-img {
  overflow: hidden;
  max-height: 250px;
  min-height: 250px;
}

.portfolio .portfolio-item .portfolio-img img {
  transition: all 0.6s;
  max-height: 250px;
  min-height: 250px;
  object-fit: cover;
  width: 100%;
}

.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 12px;
  bottom: 0;
  z-index: 3;
  right: 12px;
  transition: all 0.3s;
  background: rgba(55, 81, 126, 0.8);
  padding: 10px 15px;
  height: 100%;
}

.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 50%;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #fff;
  transition: 0.3s;
}

.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #47b2e4;
}

.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}

.portfolio .portfolio-item:hover .portfolio-img img {
  transform: scale(1.15);
}

.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
}
