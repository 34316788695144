
.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
    text-align: center;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}
.custom-banner .call-btn{
    background: #fff;
    color: #143382;
    border-radius: 2rem;
    padding: 10px 30px !important;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    border: none;
    text-transform: none;
    text-align: center;
    border: 1px solid #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
}
.custom-banner .call-btn:hover{
    color: #fff;
}
.custom-banner .call-btn:before, .custom-banner .call-btn:after {
    content: "";
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #143382;
    z-index: -1;
    -webkit-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    border-radius: 2rem;
    -webkit-transform: scaleY(1.1);
    -ms-transform: scaleY(1.1);
    transform: scaleY(1.1);
}
.custom-banner .call-btn:hover::before, .custom-banner .call-btn:hover:after, .custom-banner .call-btn.active::before, .custom-banner .call-btn.active:after {
    border-radius: 0;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}
.custom-banner .call-btn:before, .custom-banner .call-btn:after {
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
}

.sucess-story-hire{
    padding: 6rem 0;
}
.sucess-story-hire .content .part{
    display: flex;
    gap: 1rem;
}
.sucess-story-hire .content .part .icon{
    height: 80px;
    width: 80px;
}
.sucess-story-hire .content .part .icon img{
    max-width: 60px;
}
.sucess-story-hire .content .part .part-content .small-head{
    font-size: 20px;
    color: #141d38;
    font-weight: 600;
    margin-bottom: 0.4rem;
}

.work-process-hire{
    position: relative;
    overflow: hidden;
    text-align: center;
}
.work-process-hire .image-text{
    position: relative;
}
.work-process-hire .image-text .image{
    background: #f4f9fd;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    border: 1px solid #0f328542;
}
.work-process-hire .image-text .text{
    background: linear-gradient(#0f3285, #0f3285);
    border-radius: 30px;
    margin: 0 3rem;
    padding: 2rem;
    color: #fff;
    position: relative;
    top: -5rem;
    z-index: 2;
}
.work-process-hire .image-text .text .head, .work-process-hire .image-text .text .head span{
    color: #fff;
}
.work-process-hire .image-text .text .connect-btn{
    background: #fff;
    color: #143382;
    border-radius: 2rem;
    padding: 10px 30px !important;
    text-wrap: nowrap;
    position: relative;
    z-index: 2;
    overflow: hidden;
    vertical-align: middle;
    display: inline-block;
    border: none;
    text-transform: none;
    text-align: center;
    border: 1px solid #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
}

.our-company-sec-hire{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
    min-height: 100vh;
}
.our-company-sec-hire .head{
    text-align: center;
}
.our-company-sec-hire .icons{
    text-align: center;
    padding: 30px;
    border: 1px solid #ebebeb;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    background: #fff;
    height: 100%;
}
.our-company-sec-hire .icons:hover{
    
}

.our-company-sec-hire .icons p{
    font-size: 18px;
    color: #141d38;
    font-weight: 500;
    margin-bottom: 0;
}
.our-company-sec-hire .icons .icon{
    height: 90px;
    width: 90px;
    line-height: 90px;
    border-radius: 50%;
    font-size: 48px;
    margin: 0px auto 15px;
    color: #2a1fbc;
    background: #ffffff;
}
.our-company-sec-hire .icons .icon img {
    transition: 0.4s ease-in-out;
    max-width: 50px;
}
.our-company-sec-hire .icons:hover .icon {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

.connect-hire{
    background-image: url(../../public/images/strip-bg.png);
    padding: 6rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect-hire .head, .connect-hire .head span{
    color: #fff;
}
.connect-hire .process-points{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}
.connect-hire .process-points .point{
    padding: 1rem;
    border-radius: 10px;
}
.connect-hire .process-points .point:hover{
    background: #ffffff13;
}
.connect-hire .process-points .point .icon{
    background: #ffffff1f;
    height: 50px;
    width: 50px;
    margin-bottom: 1rem;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    position: relative;
}
.connect-hire .process-points .point p{
    font-size: 15px;
    line-height: 1.3;
}
.connect-hire .process-points .point p span{
    color: #ffd600;
}
.connect-hire a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-top: 2rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect-hire a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.technologie{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
}
.technologie .head{
    text-align: center;
    margin-bottom: 3rem;
}
.technologie .part{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
    padding: 20px 10px;
    border-bottom: 3px solid transparent;
    transition: ease 0.4s;
}
.technologie .part:hover{
    border-bottom: 3px solid #023cfd;
}
.technologie .part img{
    width: 75px;
    height: 75px;
}
.technologie .part h4{
    font-size: 18px;
    margin-bottom: 0;
}





.keyword-hire{
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
    text-align: center;
}
.keyword-hire .Skill-point, .keyword-hire .keywords-point{
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 2rem;
    padding: 0;
}
.keyword-hire .Skill-point li, .keyword-hire .keywords-point li{
    background: #fff;
    border: 1px solid #0f3285;
    padding: 5px 10px;
    border-radius: 5px;
}
.keyword-hire .Skill-point li{
    background: #0f3285;
    color: #fff;
    font-weight: 500;
}


.app-mobile{
    background: #f4f9fd;
    padding: 6rem 0;
    display: flex;
    align-items: center;
}


@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .our-fetaures .part{
        flex-direction: column;
    }
    .connect .process-points{
        flex-wrap: wrap;
    }
    .work-process .image-text .text {
        margin: 0 1rem;
        padding: 1rem;
        top: -1rem;
    }
    .sucess-story-hire .content{
        text-align: center;
    }
    .sucess-story-hire .pt-5{
        padding-top: 1rem !important;
    }
    .work-process .head, .our-fetaures .head{
        text-align: center;
    }
    .work-process .part{
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}