.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.experience{
    padding: 8rem 0;
    background: #fff;
    min-height: 100vh;
}
.experience .part{
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 15px 15px 3rem 15px;
    height: 100%;
    transition: 0.4s ease-in-out;
    text-align: center;
    position: relative;
}
.experience .part:hover{
    -webkit-box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
    box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
    background: #0f3285 !important;
}
.experience .part:hover .box-title{
    color: #fff;
}
.experience .col-lg-4:nth-child(1) .part, .experience .col-lg-4:nth-child(7) .part{
    background: #f1f5ff;
}
.experience .col-lg-4:nth-child(2) .part, .experience .col-lg-4:nth-child(8) .part{
    background: #fff6ed;
}
.experience .col-lg-4:nth-child(3) .part, .experience .col-lg-4:nth-child(9) .part{
    background: #ffedf9;
}
.experience .col-lg-4:nth-child(4) .part, .experience .col-lg-4:nth-child(10) .part{
    background: #f5f5f5;
}
.experience .col-lg-4:nth-child(5) .part, .experience .col-lg-4:nth-child(11) .part{
    background: #ffeeff;
}
.experience .col-lg-4:nth-child(6) .part, .experience .col-lg-4:nth-child(12) .part{
    background: #fffae5;
}
.experience .col-lg-4:nth-child(13) .part, .experience .col-lg-4:nth-child(16) .part{
    background: #fffae5;
}
.experience .col-lg-4:nth-child(14) .part, .experience .col-lg-4:nth-child(17) .part{
    background: #f5f5f5;
}
.experience .col-lg-4:nth-child(15) .part, .experience .col-lg-4:nth-child(18) .part{
    background: #ffeeff;
}
.experience .part h3{
    font-size: 22px;
}
.experience .part:hover h3, .experience .part:hover p{
    color: #fff;
}
.experience .part .icon{
    position: relative;
    z-index: 2;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 50%;
    height: 100px;
    width: 100px;
}
.experience .part:hover .icon{
    background: #E8ECF6;
}
.experience .part .icon img {
    width: 68px;
    height: 68px;
    transition: 0.4s ease-in-out;
}
.experience .part:hover .icon img {
    transform: scale(0.8);
}
.experience .part .content{
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.experience .part .readmore-btn{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
}
.experience .part:hover  .readmore-btn:hover{
    background: #0a2464;
    color: #fff;
    border: 1px solid #fff;
}
.experience .part:hover .readmore-btn{
    color: #0a2464;
    background: #fff;
}

.cp-service{
    padding: 8rem 0;
    background: #f8faf9;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.cp-service .new-service-item.key-skill {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
    height: 90%;
    padding-bottom: 10%;
}
.cp-service .new-service-item{
    padding: 30px 30px 26px;
    background: #ffffff;
    position: relative;
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid #eee;
    border-radius: 7px;
    height: 100%;
    transition: 0.4s ease-in-out;
}
.cp-service .new-service-item:hover{
    -webkit-box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
    box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
    background: #143382;
}
.cp-service .new-service-item:hover .box-title{
    color: #fff;
}
.cp-service .shadow-box {
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 15px;
    height: 100%;
}
.cp-service .new-service-item .service-circle {
    width: 100px;
    height: 100px;
    background: #E8ECF6;
    border-radius: 100%;
    display: flex;
    margin: auto;
}
.cp-service .new-service-item .service-circle img {
    max-width: 52px;
    margin: auto;
}
.cp-service .new-service-item .horizontal-info {
    text-align: center;
    margin-bottom: 10px;
}
.cp-service .new-service-item .horizontal-info h3{
    font-size: 22px;
    margin-top: 1rem;
}
.cp-service .new-service-item .next-btn img{
    margin-left: 10px;
}
.cp-service .new-service-item:hover .horizontal-info, .cp-service .new-service-item:hover .horizontal-info h3{
    color: #fff;
}

.success-story{
    background-image: url(../../public/images/strip-bg.png);
    padding: 80px 100px;
    border-radius: 30px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.success-story .head, .success-story .head span{
    color: #fff;
}
.success-story .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.success-story .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}

.hire-developers{
    padding: 8rem 0;
    background: #f8faf9;
    min-height: 100vh;
}
.hire-developers .hireDevelopersList{
    display: flex;
    justify-content: space-between;
}
.hire-developers .hireDevelopersList h5{
    font-size: 22px;
}
.hire-developers .hireDevelopersList ul{
    list-style: none;
    padding: 0;
    overflow: hidden;
    width: 100%;
}
.hire-developers .hireDevelopersList ul li{
    position: relative;
    padding-left: 15px;
    transition: ease 0.5s;
    cursor: pointer;
    margin-bottom: 20px;
}
.hire-developers .hireDevelopersList ul li:hover{
    padding-left: 20px;
}
.hire-developers .hireDevelopersList ul li::before{
    content: ">";
    position: absolute;
    left: 0;
    font-family: monospace;
}
.hire-developers .hireDevelopersList .hireDevelopersListLeft, .hire-developers .hireDevelopersList .hireDevelopersListRight{
    min-width: 50%;
}

.hire-developers .hireDevelopersRight{
    position: relative;
    overflow: hidden;
}
.hire-developers .hireDevelopersRight .frame-list{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.hire-developers .hireDevelopersRight .frame-list img{
    height: 52px;
    width: 52px;
    position: relative;
    background: #fff;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}
.hire-developers .frame-list img:nth-child(1), .hire-developers .frame-list img:nth-last-child(1){
    left: 10rem;
}
.hire-developers .frame-list img:nth-child(2), .hire-developers .frame-list img:nth-last-child(2){
    left: 7rem;
}
.hire-developers .frame-list img:nth-child(3), .hire-developers .frame-list img:nth-last-child(3){
    left: 4rem;
}
.hire-developers .frame-list img:nth-child(4), .hire-developers .frame-list img:nth-last-child(4){
    left: 2rem;
}

.serviceContent{
    padding: 8rem 0;
    background: #fff;
    min-height: 100vh;
}

.development{
    padding: 8rem 0;
    background: #f8faf9;
    min-height: 100vh;
}
.development .development-process .process-service{
    text-align: center;
    margin-bottom: 30px;
    height: 90%;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 25px;
    padding: 15px;
    gap: 1rem;
}
.development .development-process .process-service:hover {
    -webkit-box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
    box-shadow: 0 5px 15px rgba(60, 76, 198, 0.3);
}
.development .development-process .process-service img{
    width: 52px;
    height: 52px;
    filter: invert(1);
    opacity: 0.6;
    margin-top: 1rem;
}
.development .development-process .process-service p{
    margin-bottom: 0;
}
.development .development-process .process-service h5{
    font-size: 22px;
    margin-bottom: 1rem;
}
.development .development-process .process-service .content{
    margin-top: 1rem;
}

.solutions{
    background: #001850;
    padding: 5rem;
    text-align: center;
    color: #fff;
    position: relative;
    overflow: hidden;
    z-index: 1;
    height: auto;
}
.solutions .head, .solutions .head span{
    color: #fff !important;
}
.solutions::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: -50%;
    height: 200%;
    width: 200%;
    background: #0f3285;
    border-radius: 50%;
    z-index: -1;
    box-shadow: inset 0 0px 0px 2px #fff;
}

@media screen and (max-width: 575px) {
    .success-story {
        padding: 80px 0;
    }
    .success-story  .btn-group {
        justify-content: center;
        display: flex;
    }
}