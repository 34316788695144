
.custom-banner{
    height: auto;
    background: url(../../public/images/custom-banner.png), linear-gradient(#0f3285, #0f3285);
    background-repeat: no-repeat;
    background-position: right center;
    padding: 8rem 0;
    color: #fff;
    display: flex;
    align-items: center;
    scroll-margin: 100px;
}
.custom-banner .banner-head{
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-transform: capitalize;
}
.custom-banner .banner-head span{
    font-size: 1.2rem;
    display: block;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-bottom: 1rem;
    font-weight: 400;
}

.our-fetaures-testing{
    background: #f4f9fd;
    padding: 6rem 0;
    position: relative;
    overflow: hidden;
}
.our-fetaures-testing .head{
    text-align: center;
    margin-bottom: 1rem;
}
.our-fetaures-testing .tagline{
    text-align: center;
    font-size: 18px;
    margin-bottom: 2rem;
}
.our-fetaures-testing .part{
    background: #ffffff;
    padding: 30px;
    -webkit-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -moz-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: ease 0.3s;
    height: 100%;
}
.our-fetaures-testing .part:hover{
    border-top: 3px solid #023cfd;
    border-bottom: 3px solid #023cfd;
}
.our-fetaures-testing .part .small-head{
    font-size: 22px;
    font-weight: 600;
    color: #021937;
    margin-bottom: 13px;
}


.connect{
    background-image: url(../../public/images/strip-bg.png);
    padding: 6rem 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: #0f3285;
    color: #fff;
    text-align: center;
}
.connect .head, .connect .head span{
    color: #fff;
}
.connect .btn-group a{
    background: #fff;
    color: #000;
    border: 1px solid #000;
    border-radius: 2rem;
    padding: 10px 30px;
    font-size: 17px;
    font-weight: 500;
    text-transform: capitalize;
    text-decoration: none;
    margin-right: 1rem;
    transition: ease 0.5s;
    text-wrap: nowrap;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.connect .btn-group a:hover{
    background: #143382;
    color: #fff;
    border: 1px solid #fff;
}



.work-process-testing{
    padding: 6rem 0;
}
.work-process-testing .head{
    margin-bottom: 1rem;
}
.work-process-testing .tagline{
    margin-bottom: 2rem;
}
.work-process-testing .part{
    background: #ffffff;
    padding: 30px;
    -webkit-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -moz-box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    box-shadow: 0px 40px 70px 0px rgba(1, 19, 44, 0.08);
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    position: relative;
    z-index: 1;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transition: ease 0.3s;
    height: 100%;
}
.work-process-testing .part:hover{
    border-top: 3px solid #023cfd;
    border-bottom: 3px solid #023cfd;
}
.work-process-testing .part .small-head{
    font-size: 22px;
    font-weight: 600;
    color: #021937;
    margin-bottom: 13px;
}


.our-company-testing {
    align-items: center;
    background: #f8faf9;
    display: flex;
    min-height: 100vh;
    padding: 8rem 0
}
.our-company-testing .head {
    margin-bottom: 3rem;
    text-align: center
}
.our-company-testing .icons {
    background: #fff;
    border: 1px solid #eee;
    border-radius: 25px;
    box-shadow: 0 4px 4px #0000000d;
    height: 100%;
    padding: 15px;
    text-align: center;
    transition: .4s ease-in-out
}
.our-company-testing .icons:hover {
    box-shadow: 0 5px 15px #3c4cc64d
}
.our-company-testing .icons p {
    color: #141d38;
    font-size: 18px;
    margin-bottom: 0
}
.our-company-testing .icons .icon {
    margin-bottom: 10px;
    position: relative;
    z-index: 2
}
.our-company-testing .icons .icon img {
    height: 80px;
    margin-left: 10px;
    transform: scale(.8);
    transition: .4s ease-in-out;
    width: 80px
}
.our-company-testing .icons:hover .icon img {
    transform: scale(1)
}


.app-mobile-testing{
    padding: 6rem 0;
    display: flex;
    align-items: center;
}
.app-mobile-testing .hire-btn {
    text-wrap: nowrap;
    align-items: center;
    background: #fff;
    border: 1px solid #000;
    border-radius: 2rem;
    color: #000;
    display: inline-flex;
    font-size: 17px;
    font-weight: 500;
    gap: 10px;
    justify-content: center;
    margin-right: 1rem;
    padding: 10px 30px;
    text-decoration: none;
    text-transform: capitalize;
    transition: .5s ease;
}
.app-mobile-testing .hire-btn:hover {
    background: #143382;
    border: 1px solid #fff;
    color: #fff;
}


.trusted-testing{
    padding: 6rem 0;
    background: #f4f9fd;
}
.trusted-testing .mySwiper3 .swiper-slide img{
    object-fit: cover;
    background: #fff;
    border-radius: 7px;
    box-shadow: 0 4px 10px 1px #0f328524;
    margin: 10px 5px;
    transition: ease 0.5s;
    max-height: 90px
}
.trusted-testing .mySwiper3 .swiper-slide:hover img{
    padding: 10px;
}

@media screen and (max-width: 768px) {
    .custom-banner .banner-head{
        font-size: 2rem;
    }
    .connect .btn-group{
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
}